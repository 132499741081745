import '@clearscore/config.date-time-zones';

import * as connectReactRouter from 'connected-react-router';
import * as reactRedux from 'react-redux';
import * as reactRouterDom from 'react-router-dom';
import * as redux from 'redux';
import * as reselect from 'reselect';
import * as rxjs from 'rxjs';
import * as rxjsOperators from 'rxjs/operators';
import axios from 'axios';
import cx from 'classnames';
import lodashmemoize from 'lodash.memoize';
import * as history from 'history';
import react from 'react';
import reactDOM from 'react-dom';
import * as reactQuery from 'react-query';
import intlMessageFormat from 'intl-messageformat';
import marksy from 'marksy';
import universalCookie from 'universal-cookie';
import fingerprintjs from '@fingerprintjs/fingerprintjs';
import i18next from 'i18next';
import * as reactI18next from 'react-i18next';

export const IntlMessageFormat = intlMessageFormat;
export const UniversalCookie = universalCookie;
export const Marksy = marksy;
export const React = react;
export const ReactDOM = reactDOM;
export const ReactQuery = reactQuery;
export const CreateHistory = history;
export const lodashMemoize = lodashmemoize;
export const classnames = cx;
export const Axios = axios;
export const ConnectReactRouter = connectReactRouter;
export const ReactRedux = reactRedux;
export const ReactRouterDom = reactRouterDom;
export const Redux = redux;
export const Reselect = reselect;
export const Rxjs = rxjs;
export const RxjsOperators = rxjsOperators;
export const FingerprintJS = fingerprintjs;
export const I18next = i18next;
export const ReactI18next = reactI18next;

// workaround for rollup build - setting __cs__.vendor on window object, usually done in webpack
if (process.env.MODULE === 'esm' || process.env.MODULE === 'umd') {
    window.__cs__.vendor = {
        IntlMessageFormat,
        UniversalCookie,
        Marksy,
        React,
        ReactDOM,
        ReactQuery,
        CreateHistory,
        lodashMemoize,
        classnames,
        Axios,
        ConnectReactRouter,
        ReactRedux,
        ReactRouterDom,
        Redux,
        Reselect,
        Rxjs,
        RxjsOperators,
        FingerprintJS,
        I18next,
        ReactI18next,
    };
}
